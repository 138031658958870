<template>
  <v-text-field
      class="search"
      v-model="search"
      append-icon="mdi-magnify"
      :label="label || 'Search'"
      single-line
      filled
      hide-details>
  </v-text-field>
</template>

<script>
  import { debounce } from "debounce";

  export default {
    name: 'FilterSearchComponent',
    components:{

    },
    props: {
      label: String
    },
    data: () => ({
      search: null,
    }),
    mounted() {

    },
    watch: {
      search: debounce(function(search) {
        this.search = search.trim();
        this.$emit('search', this.search)
      }, 750),
    },
    methods:{

    }
  }
</script>

<style lang="scss" scoped>

</style>
