export const MODULE_NAME = 'account';

export const ACTIONS = {
  CHECK_USER: `${MODULE_NAME}/CHECK_USER`,
  CHECK_TOKEN: `${MODULE_NAME}/CHECK_TOKEN`,
  SET_CURRENT_USER: `${MODULE_NAME}/SET_CURRENT_USER`,
  GET_ME: `${MODULE_NAME}/GET_ME`,
  LOGOUT: `${MODULE_NAME}/LOGOUT`,
  LOGIN: `${MODULE_NAME}/LOGIN`,
  RESET_PASSWORD: `${MODULE_NAME}/RESET_PASSWORD`,
  MAIL_RESET_PASSWORD: `${MODULE_NAME}/MAIL_RESET_PASSWORD`,
  SET_USER_SELECT_DIALOG: `${MODULE_NAME}/SET_USER_SELECT_DIALOG`,
};

export const GETTERS = {
  IS_LOGGED_IN: `${MODULE_NAME}/IS_LOGGED_IN`,
  ROLE: `${MODULE_NAME}/ROLE`,
  RIGHTS: `${MODULE_NAME}/RIGHTS`,
};


export const MUTATIONS = {
  LOGOUT: `${MODULE_NAME}/LOGOUT`,
  SET_USER: `${MODULE_NAME}/SET_USER`,
  SET_INITIAL_USER: `${MODULE_NAME}/SET_INITIAL_USER`,
  SET_TOKEN_VALID: `${MODULE_NAME}/SET_TOKEN_VALID`,
  SET_CREDENTIALS: `${MODULE_NAME}/SET_CREDENTIALS`,
  SET_USER_SELECT_DIALOG: `${MODULE_NAME}/SET_USER_SELECT_DIALOG`,
};
