<template>
  <v-container class="dark" fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm6 lg3 justify-center>
        <v-img class="ma-3"
               height="200px"
               contain
               v-bind:src="require('@/assets/logo.png')"
               alt="Zonnekamp"
        ></v-img>
        <div class="pa-4">
          <v-form dark v-model="valid" ref="form">
            <v-text-field
              label="Email"
              v-model="mail"
              :rules="emailRules"
              solo
              required
            ></v-text-field>
            <v-layout class="right">
              <v-btn block color="secondary" aria-label="Login" @click="handleSubmit">Vraag Wachtwoord op</v-btn>
            </v-layout>
          </v-form>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex';
  import { ACTIONS } from './account.constants';
  import { ACTIONS as TOAST_ACTIONS } from '../components/toast/toast.constants';

  export default {
    data() {
      return {
        mail: '',
        password: '',
        submitted: false,
        e1: true,
        valid: false,
        emailRules: [
          v => !!v || 'E-mail is een verplicht veld',
          // eslint-disable-next-line no-useless-escape
          v => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'gelieve een geldig e-mail adress in te vullen',
        ],
      };
    },
    methods: {
      ...mapActions({
        makeToast: TOAST_ACTIONS.MAKE_TOAST,
        resetPassword: ACTIONS.MAIL_RESET_PASSWORD,
      }),
      handleSubmit() {
        this.$refs.form.validate();
        const { mail } = this;
        if (this.valid) {
          this.resetPassword(mail).then(() => {
            this.makeToast({ message: 'nieuw wachtwoord wordt gemaild', type: 'success' });
          }, (error) => {
            this.logger.info('login fail', error);
            this.makeToast({ message: 'probleem met aanvragen nieuw wachtwoord', type: 'error' });
          });
        }
      },
    },
  };
</script>

<style lang="scss">
  .v-text-field__details {
    margin-bottom: 8px;
    margin-top: 5px;
  }

  .v-text-field__slot input {
    margin-top: 2px;
  }

  button {
    &.v-btn--disabled {
      color: #e0e0e0 !important;
    }
  }
</style>
