import axios from 'axios';
import router from '@/config/router';
import http from '../services/backendApi';
import { ACTIONS, GETTERS, MUTATIONS } from './account.constants';
import api from '../services/backendApi';

export const Roles = {
  ADMIN: 'admin',
  SUPPORT: 'support',
  COACH: 'coach',
  RENT: 'rent'
}

const account = {
  state: {
    initialUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    userId: localStorage.getItem('userId') || '',
    status: null,
    validToken: false,
    accessToken: localStorage.getItem('token') || '',
  },
  getters: {
    [GETTERS.IS_LOGGED_IN]: state => !!state.accessToken,
    [GETTERS.ROLE]: state => state.user ? state.user.role : null,
    [GETTERS.RIGHTS]: state => state.user ? state.user.rights : null,
  },
  actions: {
    [ACTIONS.LOGIN]({ commit }, { username, password }) {
      const options = {
        headers: {
          authorization: `Basic ${btoa(`${username}:${password}`)}`,
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      const body = {
        access_token: process.env.VUE_APP_KEY,
      };
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}/auth/?access_token=${process.env.VUE_APP_KEY}`, body, options).then((response) => {
          if (response.data.token) {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('userId', response.data.user._id);
            localStorage.setItem('user', JSON.stringify(response.data.user));
            commit(MUTATIONS.SET_CREDENTIALS, response.data);
            resolve();
          }
        }).catch(() => {
          reject();
        });
      });
    },
    [ACTIONS.CHECK_USER]({ dispatch, commit, state }) {
      if (state.userId && state.accessToken) {
        http.get(`${process.env.VUE_APP_API_URL}/users/me`)
          .then(
            (response) => {
              commit(MUTATIONS.SET_INITIAL_USER, response.data);
            },
            () => {
              dispatch(ACTIONS.LOGOUT);
            },
          );
      } else {
        dispatch(ACTIONS.LOGOUT);
      }
    },
    [ACTIONS.GET_ME]({ commit }) {
      http.get(`${process.env.VUE_APP_API_URL}/users/me`)
        .then(
          (response) => {
            commit(MUTATIONS.SET_USER, response.data);
          },
        );
    },
    [ACTIONS.CHECK_TOKEN]({ commit }, token) {
      commit(MUTATIONS.LOGOUT);
      localStorage.setItem('token', '');
      localStorage.setItem('userId', '');
      localStorage.setItem('user', null);
      http.get(`${process.env.VUE_APP_API_URL}/password-reset/${token}`)
        .then(
          (response) => {
            commit(MUTATIONS.SET_TOKEN_VALID, response.data);
          },
        );
    },
    [ACTIONS.RESET_PASSWORD]({ commit }, { password, token }) {
      commit(MUTATIONS.LOGOUT);
      return new Promise((resolve, reject) => {
        const options = {
          headers: {
            authorization: `Basic ${window.btoa(password)}`,
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        axios.put(`${process.env.VUE_APP_API_URL}/password-reset/${token}`, null, options)
          .then(
            () => {
              resolve();
            },
          ).catch((err) => {
            reject(err);
          });
      });
    },
    [ACTIONS.MAIL_RESET_PASSWORD]({ commit }, email) {
      commit(MUTATIONS.LOGOUT);
      return new Promise((resolve, reject) => {
        http.post(`${process.env.VUE_APP_API_URL}/password-reset/mail`, { email })
          .then(() => {
            resolve();
          },
          ).catch((error) => {
            reject(error);
          });
      });
    },
    [ACTIONS.LOGOUT]({ commit }) {
      // remove user from local storage to log user out
      commit(MUTATIONS.LOGOUT);
      localStorage.setItem('token', '');
      localStorage.setItem('userId', '');
      localStorage.setItem('user', '');
      router.push('/login');
    },
    [ACTIONS.SET_CURRENT_USER]({ commit, dispatch }, user) {
      dispatch(ACTIONS.SET_USER_SELECT_DIALOG, false);
      localStorage.setItem('user', JSON.stringify(user));
      commit(MUTATIONS.SET_USER, user);
    },
    [ACTIONS.SET_USER_SELECT_DIALOG]({ commit }, value) {
      commit(MUTATIONS.SET_USER_SELECT_DIALOG, value);
    },
  },
  mutations: {
    [MUTATIONS.SET_CREDENTIALS](state, response) {
      state.accessToken = response.token;
      state.userId = response.user._id;
      state.initialUser = response.user;
      state.user = response.user;
    },
    [MUTATIONS.SET_USER](state, user) {
      state.user = user;
    },
    [MUTATIONS.SET_INITIAL_USER](state, user) {
      state.initialUser = user;
    },
    [MUTATIONS.SET_TOKEN_VALID](state, valid) {
      state.validToken = valid;
    },
    [MUTATIONS.LOGOUT](state) {
      state.accessToken = null;
      state.user = null;
      state.userId = null;
    },
  },
};

export default account;
