<template>
	<v-alert :value="true" dismissible :type="type">
		{{ message }}
	</v-alert>
</template>

<script>
	export default {
		name: 'Toast',
		props: {
			message: String,
			params: Object,
			type: String,
			icon: String,
			actions: Array,
		},
		methods: {
			emitClose() {
				this.$emit('close');
			},
		},
	};
</script>

<style scoped>

	.v-toast {
		position: relative;
		display: flex;
		flex-direction: row;
		width: 100%;
		min-height: 50px;
		margin-bottom: 10px;
		padding: 10px;
		border-radius: 2px;
		color: white !important;
	}

	.toast-body {
		flex: 1;
		display: flex;
		align-items: center;
	}

	.toast-type {
		width: 5px;
		margin-right: 5px;
	}

	.v-icon {
		cursor: default;
		color: white !important;
		margin-right: 5px;
		border-top-right-radius: 0 !important;
		border-top-left-radius: 0 !important;
	}


	/* Type styling */

	.v-toast-neutral {
		background-color: gainsboro;
		border-color: gray;
	}

	.v-toast-info {
		background-color: orange;
		border-color: orange;
		text-shadow: 0 1px 0 orange;
	}

	.v-toast-success {
		background-color: green !important;
		text-shadow: 0 1px 0 green;
	}

	.v-toast-warning {
		background-color: orange;
		border-color: orange;
		text-shadow: 0 1px 0 darkred;
	}

	.v-toast-error {
		background-color: darkred !important;
		border-color: darkred !important;
		text-shadow: 0 1px 0 darkred;
	}
</style>
