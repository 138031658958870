import { isEmpty, isNil } from 'ramda';
import { filterService } from '../services/filter.service';
import { DATA_FILTERS } from '../const';

const filterInitialState = () => {
  return {
    units: [],
    brands: [],
    projects: [],
    unitTypes: [],
    usedFilters: [],
    paymentStatuses: [],
    colorPaymentStatuses: [],
    passportStatuses: [],
    contractSignedStatuses: [],
    stayChoices: [],
    stayChoicesInfo: [],
    contractStatuses: [],
    cities: [],
    rentStatuses: [],
    textSearch: '',
    isLoading: false,
    error: null,
    contractTypes: [],
    registrationDocumentStatuses:[]
  }
};

export const FILTER_MUTATIONS = {
  SET_BRAND_FILTER_DATA: 'SET_BRAND_FILTER_DATA',
  SET_PROJECT_FILTER_DATA: 'SET_PROJECT_FILTER_DATA',
  SET_UNIT_FILTER_DATA: 'SET_UNIT_FILTER_DATA',
  SET_UNIT_TYPE_FILTER_DATA: 'SET_UNIT_TYPE_FILTER_DATA',
  SET_PAYMENT_STATUS_FILTER_DATA: 'SET_PAYMENT_STATUS_FILTER_DATA',
  SET_COLOR_PAYMENT_STATUS_FILTER_DATA: 'SET_COLOR_PAYMENT_STATUS_FILTER_DATA',
  SET_PASSPORT_STATUS_FILTER_DATA: 'SET_PASSPORT_STATUS_FILTER_DATA',
  SET_CONTRACT_SIGNED_STATUS_FILTER_DATA: 'SET_CONTRACT_SIGNED_STATUS_FILTER_DATA',
  SET_STAY_CHOICES_FILTER_DATA: 'SET_STAY_CHOICES_FILTER_DATA',
  SET_STAY_CHOICES_INFO_FILTER_DATA: 'SET_STAY_CHOICES_INFO_FILTER_DATA',
  SET_CONTRACT_STATUS_FILTER_DATA: 'SET_CONTRACT_STATUS_FILTER_DATA',
  SET_CITIES_FILTER_DATA: 'SET_CITIES_FILTER_DATA',
  SET_RENT_STATUS_FILTER_DATA: 'SET_RENT_STATUS_FILTER_DATA',
  SET_CONTRACT_TYPES_FILTER_DATA: 'SET_CONTRACT_TYPES_FILTER_DATA',
  SET_REGISTRATON_DOCUMENT_STATUS_FILTER_DATA: 'SET_REGISTRATON_DOCUMENT_STATUS_FILTER_DATA',

  SET_USED_FILTERS_WITH_DATA: 'SET_USED_FILTERS_WITH_DATA',
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',

  SET_LOADING: `SET_LOADING`,
  SET_ERROR: `SET_ERROR`,
};

export const FILTER_ACTIONS = {
  GET_BRAND_FILTER_DATA: 'GET_BRAND_FILTER_DATA',
  GET_PROJECT_FILTER_DATA: 'GET_PROJECT_FILTER_DATA',
  GET_UNIT_FILTER_DATA: 'GET_UNIT_FILTER_DATA',
  GET_UNIT_TYPE_FILTER_DATA: 'GET_UNIT_TYPE_FILTER_DATA',
  GET_PAYMENT_STATUS_FILTER_DATA: 'GET_PAYMENT_STATUS_FILTER_DATA',
  GET_COLOR_PAYMENT_STATUS_FILTER_DATA: 'GET_COLOR_PAYMENT_STATUS_FILTER_DATA',
  GET_PASSPORT_STATUS_FILTER_DATA: 'GET_PASSPORT_STATUS_FILTER_DATA',
  GET_CONTRACT_SIGNED_STATUS_FILTER_DATA: 'GET_CONTRACT_SIGNED_STATUS_FILTER_DATA',
  GET_STAY_CHOICES_FILTER_DATA: 'GET_STAY_CHOICES_FILTER_DATA',
  GET_STAY_CHOICES_INFO_FILTER_DATA: 'GET_STAY_CHOICES_INFO_FILTER_DATA',
  GET_CONTRACT_STATUS_FILTER_DATA: 'GET_CONTRACT_STATUS_FILTER_DATA',
  GET_CITIES_FILTER_DATA: 'GET_CITIES_FILTER_DATA',
  GET_RENT_STATUS_FILTER_DATA: 'GET_RENT_STATUS_FILTER_DATA',
  GET_CONTRACT_TYPES_FILTER_DATA: 'GET_CONTRACT_TYPES_FILTER_DATA',
  GET_REGISTRATON_DOCUMENT_STATUS_FILTER_DATA:'GET_REGISTRATON_DOCUMENT_STATUS_FILTER_DATA',

  UPDATE_USED_FILTERS_WITH_DATA: 'UPDATE_USED_FILTERS_WITH_DATA',

  GET_USED_FILTERS_WITH_DATA: 'GET_USED_FILTERS_WITH_DATA',
  GET_USED_FILTERS_FOR_CHIPS: 'GET_USED_FILTERS_FOR_CHIPS',

  REFRESH_FILTER_DATA: 'REFRESH_FILTER_DATA',
};

export const filterMutations = {
  [FILTER_MUTATIONS.SET_USED_FILTERS_WITH_DATA](state, data) {
    state.usedFilters = data;
  },
  [FILTER_MUTATIONS.SET_BRAND_FILTER_DATA](state, { brands }) {
    state.brands = isNil(brands) || isEmpty(brands) ? [] : brands;
    state.isLoading = false;
    state.error = null;
  },
  [FILTER_MUTATIONS.SET_PROJECT_FILTER_DATA](state, { projects }) {
    state.projects = isNil(projects) || isEmpty(projects) ? [] : projects;
    state.isLoading = false;
    state.error = null;
  },
  [FILTER_MUTATIONS.SET_UNIT_FILTER_DATA](state, { units }) {
    state.units = isNil(units) || isEmpty(units) ? [] : units;
    state.isLoading = false;
    state.error = null;
  },
  [FILTER_MUTATIONS.SET_UNIT_TYPE_FILTER_DATA](state, { types }) {
    state.unitTypes = isNil(types) || isEmpty(types) ? [] : types;
    state.isLoading = false;
    state.error = null;
  },
  [FILTER_MUTATIONS.SET_PAYMENT_STATUS_FILTER_DATA](state, { paymentStatuses }) {
    state.paymentStatuses = isNil(paymentStatuses) || isEmpty(paymentStatuses) ? [] : paymentStatuses;
    state.isLoading = false;
    state.error = null;
  },
  [FILTER_MUTATIONS.SET_COLOR_PAYMENT_STATUS_FILTER_DATA](state, { colorPaymentStatuses }) {
    state.colorPaymentStatuses = isNil(colorPaymentStatuses) || isEmpty(colorPaymentStatuses) ? [] : colorPaymentStatuses;
    state.isLoading = false;
    state.error = null;
  },
  [FILTER_MUTATIONS.SET_PASSPORT_STATUS_FILTER_DATA](state, { passportStatuses }) {
    state.passportStatuses = isNil(passportStatuses) || isEmpty(passportStatuses) ? [] : passportStatuses;
    state.isLoading = false;
    state.error = null;
  },
  [FILTER_MUTATIONS.SET_CONTRACT_SIGNED_STATUS_FILTER_DATA](state, { contractSignedStatuses }) {
    state.contractSignedStatuses = isNil(contractSignedStatuses) || isEmpty(contractSignedStatuses) ? [] : contractSignedStatuses;
    state.isLoading = false;
    state.error = null;
  },
  [FILTER_MUTATIONS.SET_STAY_CHOICES_FILTER_DATA](state, { stayChoices }) {
    state.stayChoices = isNil(stayChoices) || isEmpty(stayChoices) ? [] : stayChoices;
    state.isLoading = false;
    state.error = null;
  },
  [FILTER_MUTATIONS.SET_STAY_CHOICES_INFO_FILTER_DATA](state, { stayChoicesInfo }) {
    state.stayChoicesInfo = isNil(stayChoicesInfo) || isEmpty(stayChoicesInfo) ? [] : stayChoicesInfo;
    state.isLoading = false;
    state.error = null;
  },
  [FILTER_MUTATIONS.SET_CONTRACT_STATUS_FILTER_DATA](state, { contractStatuses }) {
    state.contractStatuses = isNil(contractStatuses) || isEmpty(contractStatuses) ? [] : contractStatuses;
    state.isLoading = false;
    state.error = null;
  },
  [FILTER_MUTATIONS.SET_CITIES_FILTER_DATA](state, { cities }) {
    state.cities = isNil(cities) || isEmpty(cities) ? [] : cities;
    state.isLoading = false;
    state.error = null;
  },
  [FILTER_MUTATIONS.SET_RENT_STATUS_FILTER_DATA](state, { rentStatuses }) {
    state.rentStatuses = isNil(rentStatuses) || isEmpty(rentStatuses) ? [] : rentStatuses;
    state.isLoading = false;
    state.error = null;
  },
  [FILTER_MUTATIONS.SET_REGISTRATON_DOCUMENT_STATUS_FILTER_DATA](state, { documentStatuses }) {
    state.registrationDocumentStatuses = isNil(documentStatuses) || isEmpty(documentStatuses) ? [] : documentStatuses;
    state.isLoading = false;
    state.error = null;
  },
  

  [FILTER_MUTATIONS.SET_CONTRACT_TYPES_FILTER_DATA](state, { contractTypes }) {
    state.contractTypes = isNil(contractTypes) || isEmpty(contractTypes) ? [] : contractTypes;
    state.isLoading = false;
    state.error = null;
  },

  [FILTER_MUTATIONS.SET_SEARCH_TEXT](state, { textSearch }) {
    state.textSearch = isNil(textSearch) || isEmpty(textSearch) ? '' : textSearch;
    state.isLoading = false;
    state.error = null;
  },

  [FILTER_MUTATIONS.SET_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [FILTER_MUTATIONS.SET_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
};

const filterActions = {
  [FILTER_ACTIONS.REFRESH_FILTER_DATA]({ commit, dispatch, state }, selectedFilter) {

    state.usedFilters.forEach((filter) => {
      if (filter.selectedOption.dataStoreAction) {
        dispatch(FILTER_ACTIONS[filter.selectedOption.dataStoreAction]);
      }
    });
  },
  [FILTER_ACTIONS.GET_USED_FILTERS_WITH_DATA]({ commit, state }) {
    return filterService.mapAllUsedFiltersWithData(state.usedFilters).then((result) => {
      if (state.textSearch.length > 0) {
        result.textSearch = state.textSearch;
      }
      return result;
    }, (error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    }).catch((error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    });
  },
  [FILTER_ACTIONS.GET_USED_FILTERS_FOR_CHIPS]({ commit, state }) {
    return filterService.mapAllUsedFiltersForChips(state.usedFilters).then((result) => {
      return result;
    }, (error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    }).catch((error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    });
  },
  [FILTER_ACTIONS.UPDATE_USED_FILTERS_WITH_DATA]({ commit }, data) {
    commit(FILTER_MUTATIONS.SET_USED_FILTERS_WITH_DATA, data);
  },
  [FILTER_ACTIONS.GET_BRAND_FILTER_DATA]({ commit }) {
    commit(FILTER_MUTATIONS.SET_LOADING, true);

    return filterService.getBrands().then(({ data }) => {
      const { data: brands } = data;
      commit(FILTER_MUTATIONS.SET_BRAND_FILTER_DATA, { brands });

    }, (error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    }).catch((error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    });
  },
  [FILTER_ACTIONS.GET_PROJECT_FILTER_DATA]({ commit, state }) {
    commit(FILTER_MUTATIONS.SET_LOADING, true);
    let params = {};

    // cities
    const citiesFilter = state.usedFilters.find(filter => { return filter.key == DATA_FILTERS.CITIES });
    if (citiesFilter && citiesFilter.selectedValues && citiesFilter.selectedValues.length > 0) {
      const cityIds = citiesFilter.selectedValues.map((city) => city._id);
      params.cities = cityIds.join(',');
    }

    return filterService.getProjects(params).then(({ data }) => {
      const { data: projects } = data;
      commit(FILTER_MUTATIONS.SET_PROJECT_FILTER_DATA, { projects });

    }, (error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    }).catch((error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    });
  },
  [FILTER_ACTIONS.GET_UNIT_TYPE_FILTER_DATA]({ commit, state }) {
    commit(FILTER_MUTATIONS.SET_LOADING, true);
    let params = {};

    // cities
    const citiesFilter = state.usedFilters.find(filter => { return filter.key == DATA_FILTERS.CITIES });
    if (citiesFilter && citiesFilter.selectedValues && citiesFilter.selectedValues.length > 0) {
      const cityIds = citiesFilter.selectedValues.map((city) => city._id);
      params.cities = cityIds.join(',');
    }

    // projects
    const projectFilter = state.usedFilters.find(filter => { return filter.key == DATA_FILTERS.PROJECTS });
    if (projectFilter && projectFilter.selectedValues && projectFilter.selectedValues.length > 0) {
      const projectIds = projectFilter.selectedValues.map((prj) => prj._id);
      params.projectId = projectIds.join(',');
    }

    return filterService.getUnitTypes(params).then(({ data }) => {
      const { data: types } = data;
      commit(FILTER_MUTATIONS.SET_UNIT_TYPE_FILTER_DATA, { types });

    }, (error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    }).catch((error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    });
  },
  [FILTER_ACTIONS.GET_UNIT_FILTER_DATA]({ commit, state }) {
    commit(FILTER_MUTATIONS.SET_LOADING, true);

    let params = {};

    // brands
    const brandFilter = state.usedFilters.find(filter => { return filter.key == DATA_FILTERS.BRANDS });
    if (brandFilter && brandFilter.selectedValues && brandFilter.selectedValues.length > 0) {
      const brandIds = brandFilter.selectedValues.map((brand) => brand._id);
      params.brandId = brandIds.join(',');
    }

    // projects
    const projectFilter = state.usedFilters.find(filter => { return filter.key == DATA_FILTERS.PROJECTS });
    if (projectFilter && projectFilter.selectedValues && projectFilter.selectedValues.length > 0) {
      const projectIds = projectFilter.selectedValues.map((prj) => prj._id);
      params.projectId = projectIds.join(',');
    }

    // types
    const typesFilter = state.usedFilters.find(filter => { return filter.key == DATA_FILTERS.UNIT_TYPES });
    if (typesFilter && typesFilter.selectedValues && typesFilter.selectedValues.length > 0) {
      const typesIds = typesFilter.selectedValues.map((type) => type._id);
      params.type = typesIds.join(',');
    }

    return filterService.getUnits(params).then(({ data }) => {
      const { data: units } = data;
      commit(FILTER_MUTATIONS.SET_UNIT_FILTER_DATA, { units });

    }, (error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    }).catch((error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    });
  },
  [FILTER_ACTIONS.GET_PAYMENT_STATUS_FILTER_DATA]({ commit, state }) {
    if (state.paymentStatuses.length > 0) {
      return state.paymentStatuses;
    }

    commit(FILTER_MUTATIONS.SET_LOADING, true);
    return filterService.getPaymentStatuses().then(({ data }) => {
      const { data: paymentStatuses } = data;
      commit(FILTER_MUTATIONS.SET_PAYMENT_STATUS_FILTER_DATA, { paymentStatuses });
    }, (error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    }).catch((error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    });
  },
  [FILTER_ACTIONS.GET_COLOR_PAYMENT_STATUS_FILTER_DATA]({ commit, state }) {
    if (state.colorPaymentStatuses.length > 0) {
      return state.colorPaymentStatuses;
    }

    commit(FILTER_MUTATIONS.SET_LOADING, true);
    return filterService.getColorPaymentStatuses().then(({ data }) => {
      const { data: colorPaymentStatuses } = data;
      commit(FILTER_MUTATIONS.SET_COLOR_PAYMENT_STATUS_FILTER_DATA, { colorPaymentStatuses });
    }, (error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    }).catch((error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    });
  },
  [FILTER_ACTIONS.GET_PASSPORT_STATUS_FILTER_DATA]({ commit, state }) {
    if (state.passportStatuses.length > 0) {
      return state.passportStatuses;
    }

    commit(FILTER_MUTATIONS.SET_LOADING, true);
    return filterService.getPassportStatuses().then(({ data }) => {
      const { data: passportStatuses } = data;
      commit(FILTER_MUTATIONS.SET_PASSPORT_STATUS_FILTER_DATA, { passportStatuses });
    }, (error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    }).catch((error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    });
  },
  [FILTER_ACTIONS.GET_CONTRACT_SIGNED_STATUS_FILTER_DATA]({ commit, state }) {
    if (state.contractSignedStatuses.length > 0) {
      return state.contractSignedStatuses;
    }

    commit(FILTER_MUTATIONS.SET_LOADING, true);
    return filterService.getContractSignedStatuses().then(({ data }) => {
      const { data: contractSignedStatuses } = data;
      commit(FILTER_MUTATIONS.SET_CONTRACT_SIGNED_STATUS_FILTER_DATA, { contractSignedStatuses });
    }, (error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    }).catch((error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    });
  },
  [FILTER_ACTIONS.GET_STAY_CHOICES_FILTER_DATA]({ commit, state }) {
    if (state.stayChoices.length > 0) {
      return state.stayChoices;
    }

    commit(FILTER_MUTATIONS.SET_LOADING, true);
    return filterService.getContractStayChoices().then(({ data }) => {
      const { data: stayChoices } = data;
      commit(FILTER_MUTATIONS.SET_STAY_CHOICES_FILTER_DATA, { stayChoices });
    }, (error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    }).catch((error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    });
  },
  [FILTER_ACTIONS.GET_STAY_CHOICES_INFO_FILTER_DATA]({ commit, state }) {
    if (state.stayChoicesInfo.length > 0) {
      return state.stayChoicesInfo;
    }

    commit(FILTER_MUTATIONS.SET_LOADING, true);
    return filterService.getContractStayChoicesInfo().then(({ data }) => {
      const { data: stayChoicesInfo } = data;
      commit(FILTER_MUTATIONS.SET_STAY_CHOICES_INFO_FILTER_DATA, { stayChoicesInfo });
    }, (error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    }).catch((error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    });
  },

  [FILTER_ACTIONS.GET_CONTRACT_STATUS_FILTER_DATA]({ commit, state }) {
    if (state.contractStatuses.length > 0) {
      return state.contractStatuses;
    }

    commit(FILTER_MUTATIONS.SET_LOADING, true);
    return filterService.getContractStatuses().then(({ data }) => {
      const { data: contractStatuses } = data;
      commit(FILTER_MUTATIONS.SET_CONTRACT_STATUS_FILTER_DATA, { contractStatuses });
    }, (error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    }).catch((error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    });
  },

  [FILTER_ACTIONS.GET_CITIES_FILTER_DATA]({ commit }) {
    commit(FILTER_MUTATIONS.SET_LOADING, true);

    return filterService.getCities().then(({ data }) => {
      const { data: cities } = data;
      commit(FILTER_MUTATIONS.SET_CITIES_FILTER_DATA, { cities });

    }, (error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    }).catch((error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    });
  },

  [FILTER_ACTIONS.GET_RENT_STATUS_FILTER_DATA]({ commit }) {
    commit(FILTER_MUTATIONS.SET_LOADING, true);

    return filterService.getRentStatuses().then(({ data }) => {
      const { data: rentStatuses } = data;
      commit(FILTER_MUTATIONS.SET_RENT_STATUS_FILTER_DATA, { rentStatuses });

    }, (error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    }).catch((error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    });
  },

  
  [FILTER_ACTIONS.GET_REGISTRATON_DOCUMENT_STATUS_FILTER_DATA]({ commit }) {
    commit(FILTER_MUTATIONS.SET_LOADING, true);

    return filterService.getContractDocumentStatuses().then(({ data }) => {
      const { data: documentStatuses } = data;
      commit(FILTER_MUTATIONS.SET_REGISTRATON_DOCUMENT_STATUS_FILTER_DATA, { documentStatuses });

    }, (error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    }).catch((error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    });
  },

  [FILTER_ACTIONS.GET_CONTRACT_TYPES_FILTER_DATA]({ commit }) {
    commit(FILTER_MUTATIONS.SET_LOADING, true);

    return filterService.getContractTypes().then(({ data }) => {
      const { data: contractTypes } = data;
      commit(FILTER_MUTATIONS.SET_CONTRACT_TYPES_FILTER_DATA, { contractTypes });

    }, (error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    }).catch((error) => {
      commit(FILTER_MUTATIONS.SET_ERROR, error);
    });
  }, 
};


export const store = {
  state: filterInitialState,
  mutations: filterMutations,
  actions: filterActions,
};
