<template>
  <v-menu
        v-model="showDateSelector"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            prepend-icon="mdi-calendar"

            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          color="light-blue accent-2"
          v-model="selected"
          range
          no-title
          @change="onRangeSelected"
        ></v-date-picker>
      </v-menu>
</template>

<script>
import {formatDate} from '../../helpers/date.helpers';
export default {
  name: "DateRangeFilter",
  props: {
    selectedItems: Array
  },

  data:()=>({
   // list:[],
    selected:[],
    showDateSelector:false
  }),

  methods:{
    onRangeSelected(){
      this.showDateSelector = false;
      this.selected = this.selected.sort();
      this.$emit('selectedChanged',this.selected);
    },

  },

  mounted: function(){
    if(this.selectedItems){
      this.selected = this.selectedItems;

    }
  },
  computed:{
    dateRangeText () {
        return this.selected.map((date)=>formatDate(date)).join(' ~ ');
    },
  }

}
</script>

<style lang="scss" scoped>
</style>
