export const menu = [
    {
        id: 'support',
        admin: true,
        path: '/admin/support',
        icon: 'mdi-handshake',
        title: 'Support',
        tooltip: 'support',
    },
    // {
    //     id: 'huurders',
    //     admin: true,
    //     path: '/admin/tenants',
    //     icon: 'mdi-account',
    //     title: 'Huurders',
    //     tooltip: 'Huurders',
    // },
    {
        id: 'units',
        admin: true,
        path: '/admin/units',
        icon: 'mdi-home-edit-outline',
        title: 'Units',
        tooltip: 'Units',
    },
    {
        id: 'projects',
        admin: true,
        path: '/admin/projects',
        icon: 'mdi-home-city-outline',
        title: 'Projects',
        tooltip: 'Projects',
    },
    {
        id: 'settings',
        admin: true,
        path: '/admin/settings',
        icon: 'mdi-cog',
        title: 'Settings',
        tooltip: 'settings',
    }
];
