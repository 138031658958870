<template>
	<v-navigation-drawer
			width="200"
			persistent
			enable-resize-watcher
			fixed
			app
	>
		<v-toolbar flat class="transparent">
			<v-list class="py-3">
				<v-list-item class="white--text mt-5">
					<v-img
							class="logo"
							cover
							width="150px"
							:src="logoLang.src"
					></v-img>
				</v-list-item>
			</v-list>
		</v-toolbar>
		<v-divider></v-divider>

		<v-list class="mt-10">
			<v-list-item
					:value="item.path === route"
					active-class="secondary--text active-route"
					class="tile"
					:class="{ 'tile': mini }"
					v-for="(item, i) in filteredRoutes"
					:key="i"
					:to="item.path === '#' ? '' : item.path"
					v-if="hideItem(item.id)"
			>
				<v-list-item-action>
					<v-icon class="icon" :color="item.path === route? 'secondary': ''" v-html="item.icon"></v-icon>
				</v-list-item-action>

				<v-list-item-title class="white--text" v-text="item.title" ></v-list-item-title>

			</v-list-item>
		</v-list>
		<v-spacer class="spacer"></v-spacer>

		<v-list>

		</v-list>
		<!--<v-divider></v-divider>-->
	</v-navigation-drawer>
</template>

<script>
	import { mapState, mapGetters } from 'vuex'
	import { menu } from '../../config/menu';
	import { GETTERS } from '@/account/account.constants';

	export default {
		props: {
			mini: {
				type: Boolean,
			},
		},
		name: 'sidebar',
		data() {
			return {
				width: '400px',
				route: '',
				logo: { src: require('@/assets/logo.png') },
				logoLang: { src: require('@/assets/logo.png') },
			};
		},
		mounted() {
			console.log(this.getRights)
		},
		computed: {
			...mapState({
				alert: state => state.alert,
				account: state => state.account.initialUser,
				drawerState: state => state.app.drawer,
			}),
			...mapGetters({
				getRights: GETTERS.RIGHTS
			}),
			filteredRoutes() {
				return [...menu];
			},
			drawer: {
				get() {
					return this.drawerState;
				},
				set(value) {
					console.log(value);
				},
			},
		},
		watch: {
			$route(route) {
				this.route = route.path;
			},
		},
		methods: {
			hideItem(value) {
				let rights = this.getRights;
				switch(value.toLowerCase()) {
					case 'settings':
						return rights.SETTINGS_MAIL_TEMPLATES.toLowerCase() === 'none' && rights.SETTINGS_MOLLIE.toLowerCase() === 'none' && rights.SETTINGS_USERS.toLowerCase() === 'none' ? false : true;

					case 'projects':
						return rights.PROJECTS_INFO.toLowerCase() === 'none' ? false : true

					default:
						return true
				}
			},
		}
	};
</script>


<style lang="scss">
	.spacer {
		display: flex;
		flex-direction: column;
	}

	.tile {
		color: #4b537c !important;
	}

	.icon {
		width: 70px;
		color: #4b537c !important;
		font-size: 30px !important;
	}

	.active-route {
		i {
			color: #ff9800 !important;
		}
	}

	.v-navigation-drawer {
		z-index: 99999999;
		background-color: #000000 !important;
		padding: 0;
	}

	.tile a {
		padding-left: 10px;
	}

	.chevron {
		background-color: #2a2a2a;
	}

	.sidebar-logo-container {
		position: relative;
		width: 100%;
		height: 50px;
		line-height: 50px;
		background: #2b2f3a;
		text-align: center;
		overflow: hidden;
		display: inline-block;

		.v-image {
			vertical-align: middle;
			display: inline-block;
			margin-right: 5px;
		}

		.sidebar-title {
			display: inline-block;
			margin: 0;
			color: #fff;
			font-weight: 600;
			line-height: 50px;
			font-size: 14px;
			font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
			vertical-align: middle;
		}
	}
</style>
