<template>
	<v-card class="pb-2">
		<v-card-title>
			<h3>Ben je zeker?</h3>
		</v-card-title>
		<v-card-text>
			<v-layout row wrap>
				<v-flex class="ma-3">
					<p class="mb-3"> Ben je zeker dat je dit permanent wilt verwijderen? </p>
				</v-flex>
			</v-layout>
		</v-card-text>
		<v-card-actions class="mr-2 d-flex justify-center">
			<v-btn color="secondary" outlined @click="cancelClicked">Anulleer</v-btn>
			<!-- <v-spacer></v-spacer> -->
			<v-btn color="primary" @click="deleteClicked">Permanent verwijderen</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: "confirm-delete",
	props: ['item'],
	methods: {
		deleteClicked() {
			this.$emit('delete', {
				_id: this.item._id
			});
		},
		cancelClicked() {
			this.$emit('cancel');
		}
	}
}
</script>

<style lang="scss" scoped>
$primary-color: #999999;

.radio-tile-group {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	button {
		width: 40%;
		margin-top: 20px;
		margin-right: 5%;
	}
}
</style>
