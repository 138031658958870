export const MODULE_NAME = 'toast';

export const ACTIONS = {
	MAKE_TOAST: `${MODULE_NAME}/MAKE_TOAST`,
	REMOVE_TOAST: `${MODULE_NAME}/REMOVE_TOAST`,
};


export const MUTATIONS = {
	ADD_TOAST: `${MODULE_NAME}/ADD_TOAST`,
	REMOVE_TOAST: `${MODULE_NAME}/REMOVE_TOAST`,
};
