<template>
	<v-card>
		<v-card-title>
			<h3>Verander status</h3>
		</v-card-title>
		<v-card-text>
			<v-layout row wrap>
				<v-flex md12 class="pa-5">
					<div class="radio-tile-group">
						<v-btn color="green"
							   :outlined="!(pStatus == PAYMENT_STATUS.PAID)"
							   class="white--text"
							   @click="pStatus = PAYMENT_STATUS.PAID"
						>
							<v-icon dark>mdi-checkbox-marked-circle-outline</v-icon>
						</v-btn>
						<v-btn color="red"
							   :outlined="(pStatus == PAYMENT_STATUS.PAID)"
							   class="white--text"
							   @click="pStatus = PAYMENT_STATUS.UN_PAID"
						>
							<v-icon dark>mdi-close-circle-outline</v-icon>
						</v-btn>
					</div>
				</v-flex>
			</v-layout>
		</v-card-text>
		<v-card-actions class="d-flex justify-center">
			<v-btn color="" @click="downloadDocu">
				<v-icon>mdi-download</v-icon>
				Download
			</v-btn>
			<v-btn color="primary" @click="saveClicked">Controle Opslaan</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
	import PAYMENT_STATUS from './payment-status.enum';
	import api from '../../services/backendApi';

	export default {
		name: "payment-change-status",
    props: {
      status: {
        type: String,
      },
			docuId: {
				type: String,
			}
    },
		data: () => ({
      PAYMENT_STATUS,
      pStatus: '',
			basicUrl: process.env.VUE_APP_API_URL,
		}),
    mounted(){
      this.pStatus = this.status;
    },
		methods: {
			saveClicked() {
				this.$emit('save', {
					status: this.pStatus
				});
			},
			downloadDocu() {
				this.$emit('downloadPayment', this.docuId)
			}
		}
	}
</script>

<style lang="scss" scoped>
	$primary-color: #999999;

	.radio-tile-group {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		button {
			width: 40%;
			margin-top: 20px;
			margin-right: 5%;
		}
	}
</style>
