<template>
  <v-container class="dark" fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm6 lg4 justify-center>
        <v-img class="ma-3"
               height="150px"
               contain
               v-bind:src="require('@/assets/logo.png')"
               alt="Upgrade Estate"
        ></v-img>
        <div class="pa-4">
          <v-form dark v-model="valid" ref="form" v-if="validToken">
            <v-text-field
              label="Geef je wachtwoord in"
              v-model="password"
              solo
              lazy
              required
              :append-icon="e1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="e1 = !e1"
              :type="e1 ? 'password' : 'text'"
              :rules="passwordRules"
            ></v-text-field>
            <v-text-field
              label="Herhaal je wachtwoord"
              v-model="repeatPassword"
              solo
              required
              lazy
              :append-icon="e1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="e1 = !e1"
              :type="e1 ? 'password' : 'text'"
              :rules="passwordRulesRepeat"
            ></v-text-field>
            <v-layout class="right">
              <v-btn block color="secondary" aria-label="Login" @click="handleSubmit">Login</v-btn>
            </v-layout>
          </v-form>
          <h3 v-else class="white--text text-lg-center">Het ingegeven token is niet geldig of is vervallen gelieven de aanvrager te contacteren <br><br> indien u al een account hebt <v-btn block color="secondary" aria-label="Login" :to="{ path: '/login'}">Naar Login Pagina</v-btn></h3>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import { ACTIONS } from './account.constants';
  import { ACTIONS as ACTIONS_TOAST } from '../components/toast/toast.constants';

  export default {
    data() {
      return {
        password: '',
        e1: true,
        repeatPassword: '',
        token: '',
        valid: false,
        passwordRules: [
          v => !!v || 'Wachtwoord is een verplicht veld',
          // eslint-disable-next-line no-mixed-operators
          v => v && v.length >= 6 || 'Je passwoord moet langer zijn dan 6 karakters',
        ],
        passwordRulesRepeat: [
          v => !!v || 'Wachtwoord is een verplicht veld',
          // eslint-disable-next-line no-useless-escape
          v => v && v === this.password || 'Je wachtwoorden komen niet overeen',
        ],
      };
    },
    computed: {
      ...mapState({
        validToken: state => state.account.validToken,
      }),
    },
    mounted() {
      // reset login status
      this.token = this.$route.query.token;
      this.checkToken(this.token);
    },
    methods: {
      ...mapActions({
        checkToken: ACTIONS.CHECK_TOKEN,
        resetPassword: ACTIONS.RESET_PASSWORD,
        makeToast: ACTIONS_TOAST.MAKE_TOAST
        ,
      }),
      handleSubmit() {
        this.$refs.form.validate();
        const { password, token } = this;
        if (password && token) {
          this.resetPassword({ password, token }).then(() => {
            this.$router.push('/login');
            this.makeToast({ message: 'wachtwoord is aangepast', type: 'success' });
            this.logger.info('reset success');
          }, (error) => {
            this.logger.info('reset fail', error);
            this.makeToast({ message: error, type: 'error' });
          });
        }
      },
      switchVisibility() {
        this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      },
    },
  };
</script>

<style lang="scss">
  .v-text-field__details {
    margin-bottom: 8px;
    margin-top: 5px;
  }
  >>> .v-text-field__slot input {
    margin-top: 2px;
  }

  button {
    &.v-btn--disabled {
      color: #e0e0e0 !important;
    }
  }
</style>
