<template>
	<div class="d-flex align-center">
		<v-btn color="black" small dark @click="goToProfile()">
			<v-avatar color="grey lighten-4" size="25" class="mr-2" v-if="user.avatar">
					<img
						:src="basicUrl + '/images/' + user.avatar"
						:alt="user.firstName"
					>
				</v-avatar>
				<v-icon v-else class="mr-2">mdi-account-circle</v-icon> 

			
			{{user.firstName}}
		</v-btn>

		<v-btn color="black" small dark @click="$emit('logout')">
			<v-icon>mdi-exit-to-app</v-icon>
		</v-btn>

		<!-- /admin/profile -->
	</div>
</template>

<script>
	export default {
		props: {
			account: {
				type: Object,
			},
			initialUser: {
				type: Object,
			},
		},
		data: () => ({
			basicUrl: process.env.VUE_APP_API_URL,
			fav: true,
			menu: false,
			message: false,
			hints: true,
			user: {},
		}),
		mounted() {
			this.user = {...JSON.parse(localStorage.user)}
		},
		methods: {
			goToProfile() {
				if (this.$route.name != 'profile') {
					this.$router.push('/admin/profile'); 	
				}
			}
		}
	};
</script>

<style scoped>

</style>
