<template>
	<v-app :class="{'px-0': $vuetify.breakpoint.xsOnly }" class="app" :style="{ backgroundImage: 'url(' + bg + ')' }">
		<v-main class="content">
			<toast-container></toast-container>
			<router-view></router-view>
		</v-main>
	</v-app>
</template>

<script>
import { ACTIONS } from '../account/account.constants';
import ToastContainer from '../components/toast/toast-container';
import { mapActions } from 'vuex';
import axios from 'axios';
import axiosInstance from '../services/backendApi';
import { loadFromLocalise } from '../config/i18n';

export default {
	name: 'App',
	components: { ToastContainer },
	data: () => ({
		project: 'upkot'
	}),
	computed: {
		logo() {
			return require(`@assets/${this.project}.svg`);
		},
		bg() {
			return require(`@assets/backgrounds/${this.project}.png`);
		},
	},
	beforeCreate() {
		loadFromLocalise();
	},
	created() {
		axiosInstance.interceptors.response.use(
				response => response,
				error => new Promise(async (resolve, reject) => {
					if (error && error.response) {
						// Check if unauthenticated
						if (error.response.status === 401) {
							// Check if user was logged in
							if (await this.loggedIn) {
								// Try refreshing the expired jwt token
								this.tryRefresh()
										.then((newJwtToken) => {
											const requestConfig = error.config;
											requestConfig.headers.Authorization = newJwtToken;
											axios.request(requestConfig)
													.then(
															result => resolve(result),
															retryError => reject(retryError),
													);
										})
										.catch(() => {
											this.logout();
											this.$router.push({ name: 'login' });
											resolve();
										});
							} else {
								// Log out user
								this.logout();
								this.$router.push({ name: 'login' });
								resolve();
							}
						} else if (error.response.status === 403) {
							// Redirect to root / unauthorized page
							this.logout();
							this.$router.push('/admin/login');
							resolve();
						} else {
							reject(error);
						}
					} else {
						reject(error);
					}
				}),
		);
	},
	mounted: function () {
		if (this.loggedIn) {
			this.getMe();
		}
	},
	watch: {
		$route(val) {
			const url = window.location.href.toLowerCase();
			if (['login', 'password-reset', 'forgot-password', 'success','thanks'].some(function (v) {
				return url.indexOf(v) >= 0;
			})) {
				this.project = 'upgrade-estate';
			} else if (['admin'].some(function (v) {
				return url.indexOf(v) >= 0;
			})) {
				this.project = 'admin';
			} else {
				let company = this.$route.params.company;
				if (company) {
					this.project = company.toLowerCase();
				}
			}
			const language = navigator.languages ? navigator.languages[0] : (navigator.language || navigator.userLanguage)
			switch (language) {
				case 'nl':
					this.$i18n.locale = 'nl-BE';
					break;
				case 'en-US':
				case 'en-GB':
					this.$i18n.locale = 'en-GB';
					break;
				case 'fr':
					this.$i18n.locale = 'fr-FR';
					break;
				default:
					this.$i18n.locale = 'en-GB';
			}
		},
	},
	methods: {
		...mapActions({
			logout: ACTIONS.LOGOUT,
			tryRefresh: ACTIONS.CHECK_USER,
			getMe: ACTIONS.GET_ME,
		}),
	}
};
</script>

<style lang="scss">
body {
	font-family: 'Roboto', serif;
	font-size: 22px;
}

#app {
	background-size: cover;
	background-position: center center;
}

.v-application--wrap {
	min-width: 100vw !important;
}

.content {
	padding: 0 !important;
}

.app-bar {
	padding: 20px 10%;
}

@media (min-width: 960px) {
	.content {
		// padding-top: 64px !important;
	}
}

@media (max-width: 960px) {
	.content {
		padding-top: 54px !important;
	}
}

@media screen and (max-width: 37.5rem) {

}
</style>
