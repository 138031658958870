import Vue from 'vue'
import App from './app/app.vue'
import store from './store/store'
import vuetify from './plugins/vuetify';
import components from '@config/components';
import router from './config/router';
import VueExcelEditor from 'vue-excel-editor'
import i18n from './config/i18n';
import Chat from 'vue-beautiful-chat'
import  './utils'

Vue.use(VueExcelEditor);
Vue.use(Chat);

Vue.config.productionTip = false;

const entries = Object.entries(components);

for (let i = 0; i < entries.length; i++) {
  const [moduleKey, moduleDefinition] = entries[i];
  const htmName = moduleKey.replace(/([A-Z])/g, $1 => `-${$1.toLowerCase()}`);
  Vue.component(htmName, moduleDefinition);
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
