<template>
	<v-card>
		<v-card-title>
			<h3>{{ $t('general.payment.upload-picture') }}</h3>
		</v-card-title>
		<v-card-text class="text-center">
			<!-- if rotation is needed, add following attribute. Note:  -->
			<!-- :rotation="rotation" -->
			<vue-avatar
					:width="avatarWidth"
					:height="avatarHeight"
					:scale="scale"
					ref="vueavatar"
					@vue-avatar-editor:image-ready="onImageReady"
					@select-file="passportUpload($event)"
			>
			</vue-avatar>
		</v-card-text>
		<v-card-actions class="mr-2 upload--bottom">
			<label class="ml-6">
				{{ $t('general.payment.zoom-picture') }} : {{scale}}x
				<br>
				<v-slider
					hide-details="auto"
					max=3
					min="0"
					step="0.02"
					v-model='scale'
				></v-slider>
			</label>
			<v-spacer></v-spacer>
			<v-btn color="primary" @click="saveClicked" :disabled="uploadDisable" >{{ $t('general.payment.upload-picture') }}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { VueAvatar } from 'vue-avatar-editor-improved';
import { ACTIONS as TOAST_ACTIONS } from '../toast/toast.constants';
import { mapActions } from 'vuex';

export default {
	components: {
		VueAvatar,
	},
	props: {
		avatarSize: {
			type: Object
		}
	},
	data() {
		return {
			// avatarWidth: 600,
			// avatarHeight: 350,
			uploadDisable: true,
			rotation: 0,
			scale: 1,
			borderRadius: '200px',
		};
	},
	mounted() {
		this.$refs.vueavatar.image = null;

		if (window.innerWidth < 600) {
			console.log('jabra')
			this.avatarWidth = 275
			this.avatarHeight = 275
		}
	},
	computed: {
		avatarWidth() {
			return this.avatarSize ? this.avatarSize.width : 600
		},
		avatarHeight() {
			return this.avatarSize ? this.avatarSize.height : 350
		},
	},
	methods: {
		...mapActions({
			makeToast: TOAST_ACTIONS.MAKE_TOAST,
		}),
		passportUpload: function passportUpload(file) {
			if (file.length != 0) {
				this.uploadDisable = false
			}
		},
		saveClicked: function saveClicked() {
			const img = this.$refs.vueavatar.getImageScaled();
			img.toBlob((blob) => {
				this.$emit('uploadAvatar', blob);
			}, 'image/png');
		},
		onImageReady: function onImageReady() {
            this.scale = 1;
            this.rotation = 0;
        },
	},
};
</script>

<style lang="scss" scoped>
.upload {
	&--bottom {

		@media screen and (max-width: 37.5rem) {
			margin: 0 !important;
			display: flex;
			flex-direction: column;

			label {
				margin: 0 0 1rem !important;
				width: 100%
			}

			button {
				margin-bottom: 1rem
			}
		}
	}
}

</style>
