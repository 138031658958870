<template>
  <div class="input--container d-flex justify-space-between">
    <v-select class="input--filter" :items="sortedFilterOptionsList" item-text="label" v-model="select" return-object item-disabled="disable"></v-select>
    <dynamic-filter-component v-if="select"
      :component="select.component"
      :dataStoreAction="select.dataStoreAction || null"
      :dataStorePath="select.dataStorePath || null"
      :value="select.value"
      :label="select.label"
      :selectedItems="selectedChildItems"
      :namespace="namespace"
      @selectedChanged="onSelectedChanged"></dynamic-filter-component>
  </div>
</template>
<script>
  import DynamicFilterComponent from './dynamic-filter.component.vue';
  export default {
    name: 'FilterSelectorComponent',
    components:{
      DynamicFilterComponent
    },
    props: {
      filterOptions: Array,
      selectedItems: Array,
      selectedOption: Object,
      namespace: String
    },
     mounted() {
      this.select = this.selectedOption || null;
      this.selectedChildItems = this.selectedItems;
     },
    data: ()=>({
      select: null,
      selectedChildItems:[]
    }),
     watch:{
      select: function(newVal,oldVal){
        if(this.select){
          this.$emit('selectedFilter',{key:this.select.value,selected:this.select});
        }
      }
    },

    methods:{
      onSelectedChanged(selectedItems){
        this.$emit('filterSelections',selectedItems);
      }
    },
    computed:{
      sortedFilterOptionsList(){
        const nSet =  [...new Set(this.filterOptions)];

        return nSet.sort((a,b)=>(a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)); //asc
      }
    }
  }
</script>

<style lang="scss" scoped>
  .input {
    &--container {
      width: 100%;
    }

    &--filter {
      max-width: 40%;
    }
  }
</style>
