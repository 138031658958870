

const formatDate = (date) => {
  if (!date) return null;
  const [year, month, day] = date.split('T')[0].split('-');
  return `${day}/${month}/${year}`;
};

export {
  formatDate
};
