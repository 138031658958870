import * as payment from '@views/payment';
import * as toast from '@components/toast';
import * as account from '../account';
import * as stayingUser from '@views/stayinguser';

// ADMIN
import * as users from '@views/admin/users';
import * as units from '@views/admin/units';
import * as projects from '@views/admin/projects';
import * as entries from '@views/admin/entries';
import * as companies from '@views/admin/companies';
import * as staying from '@views/admin/staying';
import * as rent from '@views/admin/rent';
import * as mailTemplates from '@views/admin/mail-templates';
import * as profile from '@views/admin/profile';
import * as permissions from '@views/admin/permissions';
import * as tenants from '@views/admin/tenants';
import * as paymentthanks from '@views/payment/payment-thanks';

const modules = {
  account,
  payment,
  toast,
  users,
  units,
  units, entries,
  companies,
  staying,
  stayingUser,
  rent,
  mailTemplates,
  projects, 
  profile,
  permissions,
  tenants,
  paymentthanks
};

export default modules;
