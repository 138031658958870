<template>
  <v-autocomplete class="filter-select"
  v-model="selected"
  item-text="name"
  :items="listItems"
  @change="onSelected"
  return-object
  hide-selected
  multiple
  small-chips
  hide-details>
    <template v-slot:selection="{  item, parent}">
      <div class="selected-item" :class="item._id">
        {{item.name}}
        <v-icon small @click="parent.selectItem(item)">
          $delete
        </v-icon>
      </div>
    </template>
    <template v-slot:item="{  item }">
      <div class="list-item" :class="item._id"> {{item.name}} </div>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "ColorPaymentStatusFilter",
  props: {
    selectedItems: Array,
    listItems: Array
  },

  data:()=>({
   // list:[],
    selected:[],
  }),

  methods:{
    onSelected(){
      this.$emit('selectedChanged',this.selected);
    },

  },

  mounted: function(){
    if(this.selectedItems){
      this.selected = this.selectedItems;

    }
  }
}
</script>

<style lang="scss" scoped>
  @import 'src/styles/global-color-codes.scss';

  .filter-select {
    max-width: 300px;
  }

  .list-item{
    font-size: 0.875rem;
    width:100%;
    padding:5px 10px !important;
    margin:1px 0;
    border-radius:5px;
  }

  .selected-item{
    display:flex;
    font-size: 0.6rem;
    padding:5px !important;
    margin:1px 0;
    border-radius:5px;
    color:#000;
  }
</style>
