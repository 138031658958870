<template>
  <div class="dyn-comp input--dynamic">
    <component :is="component" :listItems="listItems" :selectedItems="selectedItems" @selectedChanged="onSelectedChanged"></component>
  </div>
</template>

<script>
import { mapActions,mapState } from 'vuex';
import MultiSelectFilter from './filters/MultiSelectFilter.vue';
import DateRangeFilter from './filters/DateRangeFilter.vue';
import ColorPaymentStatusFilter from './filters/ColorPaymentStatusFilter.vue';
import DateFilter from './filters/DateFilter.vue';
import { pathOr } from "ramda";
export default {
  name:"DynamicFilterComponent",
  components:{
    MultiSelectFilter,
    DateRangeFilter,
    ColorPaymentStatusFilter,
    DateFilter
  },
  props: {
    selectedItems: Array,
    component: String,
    value: String,
    label: String,
    dataStoreAction: String,
    dataStorePath: Array,
    namespace: String
  },

  data:()=>({
    //listItems:[]
  }),

  mounted: function(){
    //this.getListData();

  },
  watch:{
    value: function(newVal,oldVal){
      console.log(`val dyn filter :: ${newVal} - ${oldVal}`);
     //this.getListData();
    }
  },

  methods: {
    ...mapActions({
      // getListData(dispatch, payload){
      //   return dispatch(`${this.dataStoreAction}`, payload)
      // }
    }),
    onSelectedChanged(selectedItems){
      this.$emit('selectedChanged',selectedItems);
    }
  },
  computed: {
    ...mapState({
      listItems(state){
        if(this.dataStorePath){
          const path = [this.namespace, ...this.dataStorePath];
          return pathOr([],path,state)
        }
        return [];
      }
    })
  }
}
</script>

<style lang="scss" scoped>
  .input {
    &--dynamic {
      width: 55%;
    }
  }
</style>
