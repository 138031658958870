import api from '@/services/backendApi';
import { DATA_FILTERS_COMPONENTS } from '../const';
import { formatDate } from '../helpers/date.helpers';
const baseUrl = `${process.env.VUE_APP_API_URL}`;
const config = {
  headers: {
    'Content-Type': 'application/json'
  },
};
export const filterService = {
  mapAllUsedFiltersForChips: async (usedFilters) => {
    const chipList = [];

    usedFilters.forEach(filter => {
      const chip = { parent: filter.selectedOption.label, values: '', index: filter.key, filter };
      const component = filter.selectedOption.component;

      switch (component) {
        case DATA_FILTERS_COMPONENTS.DATE_RANGE:
          chip.values = filter.selectedValues.map((date) => formatDate(date)).join(' ~ ');
          break;
        case DATA_FILTERS_COMPONENTS.DATE:
          chip.values = formatDate(filter.selectedValues[0]);
          break;
        default:
          chip.values = filter.selectedValues.map((val) => val.name).join(', ');
      }

      chipList.push(chip);
    });

    return chipList;

  },
  mapAllUsedFiltersWithData: async (usedFilters) => {
    let params = {};
    usedFilters.forEach(filter => {
      const key = filter.key;
      const component = filter.selectedOption.component;

      switch (component) {
        case DATA_FILTERS_COMPONENTS.DATE_RANGE:
          const dates = filter.selectedValues.map(date => { return `"${date}"` }).join(',');
          params[key] = `[${dates}]`
          break;
        case DATA_FILTERS_COMPONENTS.DATE:
          params[key] = filter.selectedValues[0];
          break;
        default:
          const ids = filter.selectedValues.map(val => val._id).join(',');
          params[key] = ids;
      }
    });
    return params;
  },
  // ---
  getBrands: () => {
    const params = { minimal: true };
    return api.get(
      `${baseUrl}/brands`,
      { config, params },
    );
  },
  getProjects: (params) => {
    params.minimal = true;
    return api.get(
      `${baseUrl}/projects`,
      { config, params },
    );
  },
  getUnitTypes: (params) => {
    return api.get(
      `${baseUrl}/units/types`,
      { config, params },
    );
  },
  getUnits: (params) => {
    params.minimal = true;

    return api.get(
      `${baseUrl}/units`,
      { config, params },
    );
  },
  getPaymentStatuses: () => {
    const params = { minimal: true };

    return api.get(
      `${baseUrl}/fees`,
      { config, params },
    );
  },
  getColorPaymentStatuses: () => {
    return api.get(
      `${baseUrl}/contracts/payment-statuses`,
      { config },
    );
  },
  getPassportStatuses: () => {
    return api.get(
      `${baseUrl}/tenants/passport-statuses`,
      { config },
    );
  },
  getContractSignedStatuses: () => {
    return Promise.resolve({
      data: {
        data:
          [
            { 'name': 'Getekend', _id: true },
            { 'name': 'Niet getekend', _id: false }
          ]
      }
    })
  },
  getContractStayChoices: () => {
    return api.get(
      `${baseUrl}/contracts/stay-choices`,
      { config },
    );
  },
  getContractStayChoicesInfo: () => {
    return api.get(
      `${baseUrl}/contracts/stay-choices-info`,
      { config },
    );
  },
  getContractStatuses: () => {
    return api.get(
      `${baseUrl}/contracts/statuses`,
      { config },
    );
  },
  getCities: () => {
    return api.get(
      `${baseUrl}/projects/cities`,
      { config },
    );
  },
  getRentStatuses: () => {
    return api.get(
      `${baseUrl}/units/for-rent/statuses`,
      { config },
    );
  },   
  getContractTypes: () => {
    return api.get(
      `${baseUrl}/contracts/contract-types`,
      { config },
    );
  },
  getContractDocumentStatuses:()=>{
    return api.get(
      `${baseUrl}/contracts/contract-document-statuses`,
      { config },
    );
  }
}
