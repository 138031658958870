import Vue from 'vue';
import Router from 'vue-router';
import store from '../store/store';
import { GETTERS } from '../account/account.constants';
import root from '@views/root/root'
import paymentPage from '@views/payment/list/payment-page'
import stayingPage from '@views/stayinguser/list/stayinguser-page'
import paymentUserPage from '@views/payment/payment-user-info/payment-user-page'
import loginPage from '../account/login'
import resetPassword from '../account/newUser'
import forgotPassword from '../account/forgot_password'
import adminRoot from '../views/admin/root/admin-root'
import userList from '../views/admin/users/list/user-list'
import unitList from '../views/admin/units/list/unit-list'
import unitDetail from '../views/admin/units/detail/unit-detail'
import tenantList from '../views/admin/tenants/list/tenant-list'
import tenantDetail from '../views/admin/tenants/detail/tenant-detail'
import projectsList from '../views/admin/projects/list/projects-list'
import entryContainer from '../views/admin/entries/list/entries-container'
import companyList from '../views/admin/companies/list/company-list'
import settingsPage from '../views/admin/settings/settings'
import profilePage from '../views/admin/profile/profile'
import supportPage from "../views/admin/support/support"
import successPage from "../views/payment/success"
import noAccess from '../views/admin/access/no-access'
import paymentThanks from '../views/payment/payment-thanks/payment-thanks'
import i18n from './i18n';
Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    
    {
      path: '/admin',
      name: 'admin',
      component: adminRoot,
      meta: {
        authentication: true,
      },
      children: [
        {
          path: 'users',
          name: 'users',
          component: userList,
          meta: {
            authentication: true,
          },
        },
        {
          path: 'entries',
          name: 'entries',
          component: entryContainer,
          meta: {
            authentication: true,
          },
        },
        {
          path: 'no-access',
          name: 'no access',
          component: noAccess,
          meta: {
            authentication: true,
          },
        },
        {
          path: 'companies',
          name: 'companies',
          component: companyList,
          meta: {
            authentication: true,
          },
        },
        {
          path: 'support',
          name: 'support',
          component: supportPage,
          meta: {
            authentication: true,
          },
        },
        {
          path: 'units',
          name: 'units',
          component: unitList,
          meta: {
            authentication: true,
          },
        },
        {
          path: 'units/:id',
          name: 'unitDetail',
          component: unitDetail,
          meta: {
            authentication: true,
          },
        },
        {
          path: 'tenants',
          name: 'tenants',
          component: tenantList,
          meta: {
            authentication: true,
          },
        },
        {
          path: 'tenants/:id',
          name: 'tenantDetail',
          component: tenantDetail,
          meta: {
            authentication: true,
          },
        },
        {
          path: 'projects',
          name: 'projects',
          component: projectsList,
          meta: {
            authentication: true,
          },
        },
        {
          path: 'settings',
          name: 'settings',
          component: settingsPage,
          meta: {
            authentication: true,
          },
          // beforeEnter: (to, from, next) => {
          //   if (store.getters[[GETTERS.ROLE]] === 'ADMIN') {
          //     next()
          //   } else {
          //     next('/admin/no-access') //Redirect to a new "no access" route
          //   }
          // }
        },
        {
          path: 'profile',
          name: 'profile',
          component: profilePage,
          meta: {
            authentication: true,
          },
        },
      ],
    },
    {
      path: '',
      name: '',
      component: root,
      redirect: '/login',
      children: [
        {
          path: '/login',
          name: 'login',
          component: loginPage,
          meta: {
            authentication: false,
          },
        },
        {
          path: '/forgot-password',
          name: 'forgot-password',
          component: forgotPassword,
          meta: {
            authentication: false,
          },
        },
        {
          path: '/password-reset',
          name: 'reset-password',
          component: resetPassword,
          meta: {
            authentication: false,
          },
        },
        {
          path: '/success',
          name: 'success',
          component: successPage,
          meta: {
            authentication: false,
          },
        },
        {
          path: 'thanks',
          name: 'paymentthanks',
          component: paymentThanks
          ,
          meta: {
            authentication: false,
          },
        },
      ],
    },
    {
      path: '/:company/:language?',
      name: '',
      component: root,
      children: [
        {
          path: 'payment-info',
          name: 'payment-info',
          component: paymentUserPage,
          meta: {
            authentication: false,
          },
        },
        {
          path: 'payment',
          name: 'payment',
          component: paymentPage,
          meta: {
            authentication: false,
          },
        },
        {
          path: 'staying',
          name: 'staying',
          component: stayingPage,
          meta: {
            authentication: false,
          },
        },
      ],
    },


  ],
});

router.beforeEach(async (to, from, next) => {
  // Check for privileges and auth requirements
  const authRequired = to.meta.authentication;
  const adminPrivsRequired = to.meta.adminAuthorization;

  const isLoggedIn = await store.getters[[GETTERS.IS_LOGGED_IN]];
  const isAdmin = await store.getters[[GETTERS.ROLES]] === 'admin';
  
  const isSupport = await store.getters[[GETTERS.ROLES]] === 'support';
  const isCoach = await store.getters[[GETTERS.ROLES]] === 'coach';

  if (to.params.language) {
    i18n.locale = to.params.language;
  }

  // Check if auth required and user is logged in or admin rights are needed and user has no admin rights
  if ((authRequired && !isLoggedIn)) {
    return router.push('/login');
  } if (adminPrivsRequired && !isAdmin) {
    return router.push('/admin/support');
  }
  // Allow access to next page
  return next();
});


export default router;
