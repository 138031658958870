
import { FILTER_ACTIONS } from "./store/filter.store";

// the names also used to send to the backend
export const DATA_FILTERS = {
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  UNITS: 'unitIds',
  UNIT_TYPES: 'types',
  PROJECTS: 'projectIds',
  BRANDS: 'brandIds',
  DEPOSIT: 'deposit',
  RENT: 'rent',
  EGWI: 'egwi',
  STUDENT_TAX: 'studentTax',
  INVENTORY: 'inventory',
  EXTRAS: 'extras',
  COLOR_PAYMENT_STATUSES: 'colorStatuses',
  PASSPORT_STATUSES: 'passportStatus',
  CONTRACT_SIGNED_STATUSES: 'signed',
  CONTRACT_SIGNED_AT: 'signedAtDate',
  STAY_CHOICES: 'stayChoices',
  STAY_CHOICES_INFO: 'stayChoicesInfo',
  CONTRACT_STATUSES: 'contractStatuses',
  AVAILABLE_RANGE: 'betweenRange',
  CITIES: 'cities',
  RENT_STATUSES: 'rentStatuses',
  CONTRACT_TYPES: 'contractTypes',
  REGISTRATION_DOCUMENT: 'registrationDocument'
};

export const DATA_FILTERS_COMPONENTS = {
  MULTI_SELECT: 'MultiSelectFilter',
  DATE_RANGE: 'DateRangeFilter',
  COLOR_PAYMENT_STATUS: 'ColorPaymentStatusFilter',
  DATE: 'DateFilter'
};

export const DATA_FILTERS_ITEMS = [
  {
    label: 'Nummer', // Units
    value: DATA_FILTERS.UNITS,
    component: DATA_FILTERS_COMPONENTS.MULTI_SELECT,
    dataStoreAction: FILTER_ACTIONS.GET_UNIT_FILTER_DATA,
    dataStorePath: ['units']
  },
  {
    label: 'Kamer type', //Unit Types
    value: DATA_FILTERS.UNIT_TYPES,
    component: DATA_FILTERS_COMPONENTS.MULTI_SELECT,
    dataStoreAction: FILTER_ACTIONS.GET_UNIT_TYPE_FILTER_DATA,
    dataStorePath: ['unitTypes']
  },
  {
    label: 'Project', //Projects
    value: DATA_FILTERS.PROJECTS,
    component: DATA_FILTERS_COMPONENTS.MULTI_SELECT,
    dataStoreAction: FILTER_ACTIONS.GET_PROJECT_FILTER_DATA,
    dataStorePath: ['projects']
  },
  {
    label: 'Merken', //Brands
    value: DATA_FILTERS.BRANDS,
    component: DATA_FILTERS_COMPONENTS.MULTI_SELECT,
    dataStoreAction: FILTER_ACTIONS.GET_BRAND_FILTER_DATA,
    dataStorePath: ['brands']
  },
  {
    label: 'Start', //Start Date
    value: DATA_FILTERS.START_DATE,
    component: DATA_FILTERS_COMPONENTS.DATE_RANGE
  },
  {
    label: 'Einde', //End Date
    value: DATA_FILTERS.END_DATE,
    component: DATA_FILTERS_COMPONENTS.DATE_RANGE
  },
  {
    label: 'Waarborg', //Deposit
    value: DATA_FILTERS.DEPOSIT,
    component: DATA_FILTERS_COMPONENTS.MULTI_SELECT,
    dataStoreAction: FILTER_ACTIONS.GET_PAYMENT_STATUS_FILTER_DATA,
    dataStorePath: ['paymentStatuses']
  },
  {
    label: 'Huur', //Rent
    value: DATA_FILTERS.RENT,
    component: DATA_FILTERS_COMPONENTS.MULTI_SELECT,
    dataStoreAction: FILTER_ACTIONS.GET_PAYMENT_STATUS_FILTER_DATA,
    dataStorePath: ['paymentStatuses']
  },
  {
    label: 'EGWI',
    value: DATA_FILTERS.EGWI,
    component: DATA_FILTERS_COMPONENTS.MULTI_SELECT,
    dataStoreAction: FILTER_ACTIONS.GET_PAYMENT_STATUS_FILTER_DATA,
    dataStorePath: ['paymentStatuses']
  },
  {
    label: 'Taks', //Student Tax
    value: DATA_FILTERS.STUDENT_TAX,
    component: DATA_FILTERS_COMPONENTS.MULTI_SELECT,
    dataStoreAction: FILTER_ACTIONS.GET_PAYMENT_STATUS_FILTER_DATA,
    dataStorePath: ['paymentStatuses']
  },
  {
    label: 'PB', //Inventory
    value: DATA_FILTERS.INVENTORY,
    component: DATA_FILTERS_COMPONENTS.MULTI_SELECT,
    dataStoreAction: FILTER_ACTIONS.GET_PAYMENT_STATUS_FILTER_DATA,
    dataStorePath: ['paymentStatuses']
  },
  {
    label: 'Extras',
    value: DATA_FILTERS.EXTRAS,
    component: DATA_FILTERS_COMPONENTS.MULTI_SELECT,
    dataStoreAction: FILTER_ACTIONS.GET_PAYMENT_STATUS_FILTER_DATA,
    dataStorePath: ['paymentStatuses']
  },
  {
    label: 'Status', //Payment Status
    value: DATA_FILTERS.COLOR_PAYMENT_STATUSES,
    component: DATA_FILTERS_COMPONENTS.COLOR_PAYMENT_STATUS,
    dataStoreAction: FILTER_ACTIONS.GET_COLOR_PAYMENT_STATUS_FILTER_DATA,
    dataStorePath: ['colorPaymentStatuses']
  },
  {
    label: 'Paspoort', //Passport Status
    value: DATA_FILTERS.PASSPORT_STATUSES,
    component: DATA_FILTERS_COMPONENTS.MULTI_SELECT,
    dataStoreAction: FILTER_ACTIONS.GET_PASSPORT_STATUS_FILTER_DATA,
    dataStorePath: ['passportStatuses']
  },
  {
    label: 'Getekend', //Signed
    value: DATA_FILTERS.CONTRACT_SIGNED_STATUSES,
    component: DATA_FILTERS_COMPONENTS.MULTI_SELECT,
    dataStoreAction: FILTER_ACTIONS.GET_CONTRACT_SIGNED_STATUS_FILTER_DATA,
    dataStorePath: ['contractSignedStatuses']
  },
  {
    label: 'Getekend op', //Signed At
    value: DATA_FILTERS.CONTRACT_SIGNED_AT,
    component: DATA_FILTERS_COMPONENTS.DATE
  },
  {
    label: 'Keuze', //Stay Choice
    value: DATA_FILTERS.STAY_CHOICES,
    component: DATA_FILTERS_COMPONENTS.MULTI_SELECT,
    dataStoreAction: FILTER_ACTIONS.GET_STAY_CHOICES_FILTER_DATA,
    dataStorePath: ['stayChoices']
  },
  {
    label: 'Info Keuze', //Stay Choice Info
    value: DATA_FILTERS.STAY_CHOICES_INFO,
    component: DATA_FILTERS_COMPONENTS.MULTI_SELECT,
    dataStoreAction: FILTER_ACTIONS.GET_STAY_CHOICES_INFO_FILTER_DATA,
    dataStorePath: ['stayChoicesInfo']
  },
  {
    label: 'Blijven/Vertrekken', //Contract Status
    value: DATA_FILTERS.CONTRACT_STATUSES,
    component: DATA_FILTERS_COMPONENTS.MULTI_SELECT,
    dataStoreAction: FILTER_ACTIONS.GET_CONTRACT_STATUS_FILTER_DATA,
    dataStorePath: ['contractStatuses']
  },
  {
    label: 'Datum beschikbaarheid',//Available Between
    value: DATA_FILTERS.AVAILABLE_RANGE,
    component: DATA_FILTERS_COMPONENTS.DATE_RANGE
  },
  {
    label: 'Stad', //Cities
    value: DATA_FILTERS.CITIES,
    component: DATA_FILTERS_COMPONENTS.MULTI_SELECT,
    dataStoreAction: FILTER_ACTIONS.GET_CITIES_FILTER_DATA,
    dataStorePath: ['cities']
  }, {
    label: 'Status', //Rent Statuses
    value: DATA_FILTERS.RENT_STATUSES,
    component: DATA_FILTERS_COMPONENTS.MULTI_SELECT,
    dataStoreAction: FILTER_ACTIONS.GET_RENT_STATUS_FILTER_DATA,
    dataStorePath: ['rentStatuses']
  }, {
    label: 'Contract types', //Contract types
    value: DATA_FILTERS.CONTRACT_TYPES,
    component: DATA_FILTERS_COMPONENTS.MULTI_SELECT,
    dataStoreAction: FILTER_ACTIONS.GET_CONTRACT_TYPES_FILTER_DATA,
    dataStorePath: ['contractTypes']
  },{
    label: 'Registratie Document', //Passport Status
    value: DATA_FILTERS.REGISTRATION_DOCUMENT,
    component: DATA_FILTERS_COMPONENTS.MULTI_SELECT,
    dataStoreAction: FILTER_ACTIONS.GET_REGISTRATON_DOCUMENT_STATUS_FILTER_DATA,
    dataStorePath: ['registrationDocumentStatuses']
  },
];
