import { ACTIONS, MUTATIONS } from './toast.constants';

const DEFAULT_DISMISS_TIMEOUT = 5000;
let maxToastId = 0;

const toast = {
	state: {
		messages: [],
	},
	actions: {
		[ACTIONS.MAKE_TOAST]({ commit, dispatch }, {
			message,
			params,
			type = 'success',
			icon = 'check',
			actions,
			autoDismiss = true,
			dismissAfter = DEFAULT_DISMISS_TIMEOUT,
		}) {
			maxToastId += 1;
			const id = maxToastId;

			commit(MUTATIONS.ADD_TOAST, {
				id,
				message,
				params,
				icon,
				type,
				actions,
			});

			if (autoDismiss) {
				setTimeout(() => {
					dispatch(ACTIONS.REMOVE_TOAST, id);
				}, dismissAfter);
			}
		},
		[ACTIONS.REMOVE_TOAST]({ commit }, id) {
			commit(MUTATIONS.REMOVE_TOAST, id);
		},
	},
	mutations: {
		[MUTATIONS.ADD_TOAST](state, message) {
			state.messages.push(message);
		},
		[MUTATIONS.REMOVE_TOAST](state, id) {
			state.messages = state.messages.filter(m => m.id !== id);
		},
	},
};


export default toast;
