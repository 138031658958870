<template>
  <v-menu
        v-model="showDateSelector"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateText"
            prepend-icon="mdi-calendar"

            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="selected"
          @change="onSelected"
        ></v-date-picker>
      </v-menu>
</template>

<script>
import {formatDate} from '../../helpers/date.helpers';
export default {
  name: "DateFilter",
  props: {
    selectedItems: Array
  },

  data:()=>({
   // list:[],
    selected:'',
    showDateSelector:false
  }),

  methods:{
    onSelected(){
      this.showDateSelector = false;
     this.$emit('selectedChanged',[this.selected]);
    },

  },

  mounted: function(){
    if(this.selectedItems){
     this.selected = this.selectedItems[0];

    }
  },
  computed:{
    dateText () {
      return formatDate(this.selected);
    },
  }

}
</script>

<style lang="scss" scoped>
</style>
