<template>
<div>
  <v-icon :style="editable ?{} :{cursor: 'default'}" @click="showDialog=true" v-if="iconColor && iconType" :color="iconColor">
            {{`mdi-${iconType}-circle-outline`}}
  </v-icon>
  <v-dialog v-model="showDialog" v-if='editable && showDialog'  max-width="350px">
    <payment-change-status  v-if='showDialog' :status='pStatus' :docuId="pFileId" @save='updateStatus' @downloadPayment="downloadPayment"></payment-change-status>
  </v-dialog>
</div>
  <!-- <v-dialog v-model="paymentDialog" v-if="canEdit" max-width="300px"> -->
			<!-- <payment-dialog v-if="paymentDialog" :item="paymentDialogItem" @save="updateTenantPayment"></payment-dialog> -->
		<!-- </v-dialog> -->
</template>

<script>
import PAYMENT_STATUS from './payment-status.enum';
import PaymentChangeStatus from './payment-status-change.dialog.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { ACTIONS as TOAST_ACTIONS } from '../../components/toast/toast.constants';
import axios from "axios";

export default {

  name: "payment-status",
  components: { PaymentChangeStatus },
  props: {
    status: {
      type: String,
    },
    paymentItem:{
      type: Object,
    },
    editable:{
      type: Boolean,
    },
    documents: {
      type: Array,
    }
  },

  data: vm => ({
    basicUrl: process.env.VUE_APP_API_URL + '/downloads/documents/',
    iconType: null,
    iconColor: null,
    showDialog: false,
    pStatus: '-',
    pFileId: null,
    PAYMENT_STATUS,
    document: null,
  }),

  beforeMount() {
    this.pFileId = this.paymentItem.documentId;
    this.pStatus = this.status;
    if(this.paymentItem){
      const pStat = `check:${this.paymentItem.checked}_status:${this.paymentItem.status}`;
      switch(pStat){
        case 'check:true_status:true':
            this.pStatus = PAYMENT_STATUS.PAID;
          break;
        case 'check:false_status:true':
            this.pStatus = PAYMENT_STATUS.TO_CHECK;
          break;
          default:
            this.pStatus = PAYMENT_STATUS.UN_PAID;
      }
    }
  },
  methods: {
    ...mapActions({
      makeToast: TOAST_ACTIONS.MAKE_TOAST,
    }),
    updateStatus(val) {
      this.pStatus = val.status;
      this.showDialog=false;

      this.$emit('save', {
					status: this.pStatus
				});
    },
    async downloadPayment(val) {
      if (val) {
        if(this.documents) {
          const docs = this.documents.filter(doc => doc._id == val);
          this.document = docs.length >0 ? docs[0] : null;
          await this.downloadWithAxios(`${this.basicUrl}${this.document.name}`, `${this.document.name}`);
        }
      } else {
        this.makeToast({
					message: `Er is geen ID aanwezig van de extra betaling`,
					type: 'error',
				});
      }
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    downloadWithAxios(url, title) {
      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
      })
          .then((response) => {
            this.forceFileDownload(response, title)
          })
          .catch(() => console.log('error occured'))
    },
  },
  watch: {
		pStatus(val) {
      switch(val){
        case PAYMENT_STATUS.PAID:
          this.iconType = 'checkbox-marked';
          this.iconColor = 'green';
          break;
        case PAYMENT_STATUS.TO_CHECK:
          this.iconType = 'alert';
          this.iconColor = 'orange';
          break;
        case PAYMENT_STATUS.UN_PAID:
          this.iconType = 'close';
          this.iconColor = 'red';
          break;
      }
		}
  }
}
</script>

<style lang="scss" scoped>

</style>
