<template>
  <div>
    <div class="d-flex align-center">
      <v-dialog
        v-model="showDialog"
        width="700"
      >

        <template v-slot:activator="{ on, attrs }">
          <filter-search-component v-if="showSearchBar" :label="searchLabel" @search="onSearch"></filter-search-component>
          <v-spacer v-else></v-spacer>

          <v-btn v-if="showFilters" x-large color="blue-grey" class="white--text ml-4" v-bind="attrs" v-on="on">
            <v-icon small left dark>mdi-filter-variant</v-icon>
            Filters
          </v-btn>
        </template>

        <v-card class="pb-2" id="filterModal" v-if="showDialog">
          <v-card-title>
            <h3>Filters</h3>
          </v-card-title>
          <v-card-text>
            <v-layout row wrap>
              <v-flex class="ma-3">
                <p class="mb-6">
                  Om een filter toe te voegen aan de selectie drukken we op de knop <b>Filter toevoegen</b>.
                  Gebruik de knop <b>Filter toepassen</b> om de selectie te filteren.
                </p>
                <div v-for="filter in dataFilters" v-bind:key="filter.key">
                  <div class="d-flex justify-space-between">
                    <filter-selector-component
                      :namespace="namespace"
                      :filterOptions="filterItems"
                      :selectedOption="filter.selectedOption"
                      :selectedItems="filter.selectedValues || []"
                      @selectedFilter="onFilterSelect(filter,$event)"
                      @filterSelections="onFilterSelectionChanged(filter,$event)">
                    </filter-selector-component>

                    <v-btn elevation="0" large color="white" class="delete--btn mt-3" @click="deleteFilter(filter)">
                      <v-icon medium dark>mdi-filter-remove-outline</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="d-flex justify-space-between px-6">
            <v-btn color="secondary" outlined @click="addFilter">
              <v-icon small dark left>mdi-filter-plus</v-icon>
              FILTER TOEVOEGEN
            </v-btn>
            <v-btn color="secondary" outlined @click="submitFilters">TOEPASSEN</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-right">
      <v-chip class="ma-2" small close
        v-for="chip in usedFiltersChipList" v-bind:key="chip.index" @click:close="deleteFilter(chip.filter,true)">
        {{chip.parent}}: {{chip.values}}
         <!--  @click:close="chip1 = false" -->
    </v-chip>
    </div>
  </div>
</template>

<script>
import { DATA_FILTERS_ITEMS } from './const';
import {FILTER_ACTIONS, FILTER_MUTATIONS} from './store/filter.store';

import FilterSelectorComponent from './components/filter-selector.component.vue';
import FilterSearchComponent from './components/filter-search.component.vue';

import { mapState, mapActions, mapMutations } from 'vuex';
import { pathOr } from "ramda";
export default {
  name: 'FilterDataDialogComponent',
  components: { FilterSelectorComponent, FilterSearchComponent },
	props: {
    acceptedFilters: Array,
    uniqueFilterStoreName: String,
    searchLabel: String,
    showFilters: {type: Boolean, default: false},
    showSearchBar: {type: Boolean, default: false},
  },
  data: () => ({
    showDialog:false,
    filterItems:[],
    usedFiltersChipList:[],
    namespace:''
  }),
  mounted() {
    // console.log('mount');
    this.namespace = `${this.uniqueFilterStoreName}`;
    //
    this.mapAcceptedFilters();
    this.addFilterToStore();
    //
    this.updateFilters();
    //
    this.submitFilters();
  },
  beforeCreate(){
    // console.log('before create')
  },
  created(){
    // console.log('created')
  },
	methods: {
    ...mapActions({
      updateUsedFilters(dispatch, payload){
        return dispatch( `${this.namespace}/${FILTER_ACTIONS.UPDATE_USED_FILTERS_WITH_DATA}`, payload);
      },
      refreshFilterData(dispatch, payload){
        return dispatch( `${this.namespace}/${FILTER_ACTIONS.REFRESH_FILTER_DATA}`, payload);
      },
      getAllFilterData(dispatch, payload){
        return dispatch( `${this.namespace}/${FILTER_ACTIONS.GET_USED_FILTERS_WITH_DATA}`, payload);
      },
      getAllFilterDataForChips(dispatch,payload){
        return dispatch( `${this.namespace}/${FILTER_ACTIONS.GET_USED_FILTERS_FOR_CHIPS}`, payload);
      }
    }),
    ...mapMutations({
        addFilterToStore(commit,payload){
          return commit('addFilter',this.namespace);
        },
        setSearchText(commit,payload){
          // console.log(payload);
          return commit(`${this.namespace}/${FILTER_MUTATIONS.SET_SEARCH_TEXT}`, {textSearch:payload})
        }
      }),

    onSearch(e) {
      this.setSearchText(e)
      this.submitFilters();
    },

    deleteFilter(filter,directResponseToParent=false) {
      const idOfObject = this.dataFilters.findIndex(object => {
        return object.id === filter.id;
      });

      this.dataFilters.splice(idOfObject, 1);
      this.getChipData();

      if(directResponseToParent){
        this.submitFilters();
      }

    },
    onFilterSelect(dataFilter,{key,selected}){
      // filter is changed ,
      // the selected data of this filter must be cleared
      const origKey = dataFilter.key;
      dataFilter.key = key;
      dataFilter.selectedOption = selected;
      if(origKey != key){
        dataFilter.selectedValues = [];
      }
       this.updateFilters();
    },
    onFilterSelectionChanged(dataFilter,selectedValues){
      dataFilter.selectedValues = selectedValues;
      this.updateFilters();
    },

    mapAcceptedFilters() {
      this.filterItems = DATA_FILTERS_ITEMS.filter((item)=>{
        if(this.acceptedFilters.includes(item.value)){
          item.disable = false;
          return item;
        }
      });
    },
    updateFilters(key=null){
      this.refreshFilterData(key);
      this.getChipData();
    },
    getChipData(){
      (async()=>{
          if(this.namespace){
          const chiplist = await this.getAllFilterDataForChips();
          this.usedFiltersChipList = chiplist;
        }
      })();
    },

    async submitFilters() {
      // console.log('SUBMIT FILTERS')
      const data = await this.getAllFilterData();
      this.$emit('filterData',data);
      this.showDialog = false;
    },

    addFilter() {
      let newFilter = {
        id: Date.now(),
        key: "",
        selectedValues: [],
      }

      this.dataFilters.push(newFilter);
    },
	},
  computed:{
    ...mapState({
      dataFilters(state){
        return pathOr([],[this.namespace,'usedFilters'],state)}
    }),
  },
  watch:{
     dataFilters: function(newVal,oldVal){
      const usedFilterKeys = this.dataFilters.map((df)=>{return df.key});

      this.filterItems.forEach((item)=>{
        item.disable = usedFilterKeys.includes(item.value);
      });
    },
  }
}
</script>



<style lang="scss" scoped>
  .delete {
    &--btn {
      max-width: 8% !important;
      min-width: initial !important;
      margin-left: 2rem;
      color: rgba(0, 0, 0, 0.54);
    }
  }
</style>
