<template>
  <v-container class="dark mx-auto" fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm6 lg3 justify-center>
        <v-img class="ma-3"
               height="200px"
               contain
               :src="require('@/assets/logo.png')"
               alt="Zonnekamp"
        ></v-img>
        <div class="pa-4">
          <v-form dark v-model="valid" ref="form">
            <v-text-field
              label="Username"
              v-model="username"
              solo
              required
              autocomplete="off"
            ></v-text-field>
            <v-text-field
              label="Enter your password"
              v-model="password"
              solo
              required
              :append-icon="e1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="e1 = !e1"
              :type="e1 ? 'password' : 'text'"
              :rules="passwordRules"
              autocomplete="off"
            ></v-text-field>
            <v-layout class="right">
              <v-btn outlined color="white" aria-label="Login" :to="{ path: '/forgot-password'}">Wachtwoord vergeten</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="secondary" aria-label="Login" @click="handleSubmit">Login</v-btn>
            </v-layout>
          </v-form>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex';
  import { ACTIONS } from './account.constants';
  import { ACTIONS as TOAST_ACTIONS } from '../components/toast/toast.constants';

  export default {
    data() {
      return {
        username: '',
        password: '',
        submitted: false,
        e1: true,
        valid: false,
        passwordRules: [
          v => !!v || 'Paswoord is een verplicht veld',
          // eslint-disable-next-line no-mixed-operators
          v => v && v.length >= 6 || 'Je passwoord moet langer zijn dan 6 karakters',
        ],
      };
    },
    created() {
      // reset login status
      this.logout();
    },
    methods: {
      ...mapActions({
        makeToast: TOAST_ACTIONS.MAKE_TOAST,
        logout: ACTIONS.LOGOUT,
        login: ACTIONS.LOGIN,
      }),
      handleSubmit() {
        this.$refs.form.validate();
        const { username, password } = this;
        if (this.valid) {
          this.login({ username: username.toLowerCase(), password }).then(() => {
            this.$router.push('/admin/support');
            this.logger.info('login success');
          }, (error) => {
            // this.logger.info('login fail', error);
            this.makeToast({ message: 'wachtwoord en email adres komen niet overeen', type: 'error' });
          });
        }
      },
      switchVisibility() {
        this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      },
    },
  };
</script>

<style lang="scss">
  .v-text-field__details {
    margin-bottom: 8px;
    margin-top: 5px;
  }

  >>> .v-text-field__slot input {
    margin-top: 2px;
  }

  button {
    &.v-btn--disabled {
      color: #e0e0e0 !important;
    }
  }
</style>
