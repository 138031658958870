<template>
  <v-app-bar class="header" color="black" app>
    <v-spacer></v-spacer>
    <v-toolbar-items>
        <avatar-menu :account="account" :initialUser="initialUser" @logout="logout"></avatar-menu>
    </v-toolbar-items>

  </v-app-bar>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { ACTIONS as ACOUNT_ACTIONS } from '../../account/account.constants';
  import AvatarMenu from './avatar-menu';

  export default {
    components: { AvatarMenu },
    computed: {
      ...mapState({
        initialUser: state => state.account.initialUser,
        account: state => state.account.user,
        breadcumbs: state => state.mainLayout.breadcumbs,
      }),
    },
    methods: {
      ...mapActions({
        logout: ACOUNT_ACTIONS.LOGOUT,
      }),
    },
  };
</script>

<style lang="scss" scoped>
  .v-input__slot {
    margin-bottom: 0px;
  }

  .header {
    margin-top: -64px;
    display: flex;
    justify-content: flex-end;
  }

  .v-input__slot:before {
    border-style: none !important;
  }
</style>
