<template>
  <div>
    <v-autocomplete
      v-model="selected"
      :items="listItems"
      return-object
      hide-selected
      item-text="name"
      multiple
      small-chips
      @change="emitSelected"
      deletable-chips
    >
    </v-autocomplete>
  </div>
</template>

<script>

export default {
  name: "MultiSelectFilter",
  props: {
    selectedItems: Array,
    listItems: Array
  },

  data:()=>({
   // list:[],
    selected:[]
  }),

  methods:{
    emitSelected(){
      this.$emit('selectedChanged',this.selected);
    }
  },

  mounted: function(){
    if(this.selectedItems){
      this.selected = this.selectedItems;
      //this.emitSelected();
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
